/**
* SEO component that queries for data with
*  Gatsby's useStaticQuery React hook
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO() {
  const { site, file } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
      file(relativePath: { eq: "card.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 512, height: 512) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    `
    )
    const metadata = [
      {
        name: `description`,
        content: site.siteMetadata.description,
      },
      {
        property: `og:title`,
        content: site.siteMetadata.title,
      },
      {
        property: `og:description`,
        content: site.siteMetadata.description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:site`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: site.siteMetadata.title,
      },
      {
        name: `twitter:description`,
        content: site.siteMetadata.description,
      },
      {
        name: `twitter:image`,
        content: site.siteMetadata.siteUrl + file.childImageSharp.fixed.src,
      },
      {
        name: `twitter:image:alt`,
        content: `image of a hand waving`,
      },
      {
        property: `og:image`,
        content: site.siteMetadata.siteUrl + file.childImageSharp.fixed.src,
      }
    ]
    
    return (
      <Helmet
        htmlAttributes={{lang: "en"}}
        title={site.siteMetadata.title}
        meta={metadata}
      />
      )
    }
    
    
    export default SEO
