import React from "react"

import {
  FaTwitter as Twitter,
  FaGithub as Github,
  FaEnvelope as Mail,
} from "react-icons/fa"

export default function info() {
  // weee
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-4" style={{ color: "#a7e32b" }}>
        <span style = {{color : "white"}}>Hey There!<br />
        I'm</span> Tom! <span role="img" aria-label="waving hand">👋</span>
      </div>
      <div className="code" style={{ color: "#88846f", paddingTop: "1em", fontSize: "1.15em" }}>
        {'/*'}
        <p className="my-4 ml-4 info">
          Backend Engineer well versed in Go, Python & Distributed Systems.
        </p>
        <p className="my-4 ml-4 info">
         Linux, Sysadmin, Cyber Security, Raspberry Pi, Open Source & Cloud Tech nerd.
        </p>
        <p className="my-4 ml-4 info">
          Dabbles in front-end development (React).
        </p>
        {'*/'}
      </div>
      <div className="h1 mt-5">
        <a className="mr-4 icon" href="https://twitter.com/A_Porcupine">
          <Twitter />
        </a>
        <a className="mr-4 icon" href="https://github.com/aporcupine">
          <Github />
        </a>
        <a className="mr-4 icon" href="mailto:tom@aporcupine.com">
          <Mail />
        </a>
      </div>
    </div>
  )
}
