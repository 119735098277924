import React from "react"

import styled from "styled-components"

const FooterContainer = styled.div`
padding-top: 1em;
padding-bottom: 1em;
`;

const FooterLink = styled.a`
color: #fa2173;
&:hover {
  color: #ddd271;
}
`;

export default function Appbar() {
  return (
    <FooterContainer className="container">
      <FooterLink href="https://aporcupine.com/2020/03/pi4-kubernetes-cluster/">I run on a Raspberry Pi cluster!</FooterLink>
    </FooterContainer>
    )
  }
  