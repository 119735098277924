import React from "react"
import Intent from "./link"

import styled from "styled-components"

const Link = styled.a`
  color: #fa2173;
  &:hover {
    color: #ddd271;
  }
`;

export default function Appbar() {
  return (
    <div className="container my-3">
      <ul className="nav justify-content-center">
        <li className="nav-item mx-4">
          <Intent to="/">Home</Intent>
        </li>
        <li className="nav-item mx-4">
          <Link className="nav-link" href="https://aporcupine.com">Blog</Link>
        </li>
      </ul>
    </div>
  )
}
