import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Info from "../components/info"
import Footer from "../components/footer"
import SEO from "../components/seo"

export default ({ data }) => (
  <div>
    <SEO />
    <Appbar />
    <Info />
    <Footer />
  </div>
)